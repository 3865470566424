<template>
  <div class="page-wrapper">
    <TheSidebar />
    <div class="right-content-wrapper">
      <v-data-table
        ref="table"
        :headers="headers"
        :items="items"
        :items-per-page.sync="perPage"
        :footer-props="{ 'items-per-page-options': [10, 20, 50, 100, 500] }"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDirection"
        :page.sync="page"
        :server-items-length.sync="total"
        @update:options="getItems"
        class="elevation-1"
        style="width: 100%"
        :loading="spinnerActive"
      >
        <template v-slot:top>
          <v-toolbar flat>
            <v-toolbar-title>Collaborations</v-toolbar-title>
            <v-divider class="mx-4" inset vertical></v-divider>
            <v-spacer></v-spacer>
            <v-dialog v-model="dialog" max-width="1000px">
              <template v-slot:activator="{ on, attrs }">
                <v-btn color="primary" dark class="mb-2" v-bind="attrs" v-on="on">New Item</v-btn>
              </template>
              <v-card>
                <v-form
                  ref="form"
                  @submit.prevent="save()"
                  v-model="valid"
                  action="/"
                >
                  <v-card-title>
                    <span class="text-h5">{{ formTitle }}</span>
                  </v-card-title>
                  <v-card-text>
                    <v-container>
                      <v-row>
                        <v-text-field
                          :rules="[rules.required]"
                          v-model="editedItem.title"
                          label="Title"
                        ></v-text-field>
                      </v-row>
                      <v-row>
                        <v-textarea
                          :rules="[rules.required]"
                          v-model="editedItem.description"
                          label="Description"
                        ></v-textarea>
                      </v-row>
                      <v-row>
                        <v-text-field
                          :rules="[rules.required]"
                          v-model="editedItem.url"
                          label="URL (link)"
                        ></v-text-field>
                      </v-row>
                      <v-row>
                        <v-file-input
                          accept="image/* //"
                          :rules="[!!collaborationImage || 'Required.', rules.size]"
                          placeholder="Pick an image"
                          v-model="editedItem.image"
                          :input="loadImage()"
                          label="Image"
                          prepend-icon="mdi-paperclip"
                          outlined
                          :show-size="1000"
                        >
                        </v-file-input>
                        <div style="width: 100%">
                          <img
                            v-if="collaborationImage"
                            :src="getImage(collaborationImage)"
                            height="100"
                            class="mb-4"
                          />
                        </div>
                      </v-row>
                      <v-row>
                        <v-tooltip top>
                          <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            :rules="[rules.required]"
                            v-model="editedItem.alt_image"
                            v-bind="attrs"
                            v-on="on"
                            label="Image alt"
                          ></v-text-field>
                          </template>
                          <span>The Alt tags are used to describe the image to
                            <br>search engines (cannot be seen by the user).
                            <br>Try to create a simple description of the image
                            <br>as if you were describing it to someone with their eyes closed.
                          </span>
                        </v-tooltip>
                      </v-row>
                    </v-container>
                  </v-card-text>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" text @click="close">Cancel</v-btn>
                    <v-btn color="blue darken-1" text type="submit">Save</v-btn>
                  </v-card-actions>
                </v-form>
              </v-card>
            </v-dialog>
            <v-dialog v-model="dialogDelete" max-width="1000px">
              <v-card>
                <v-card-title class="text-h5">Are you sure you want to delete this item?</v-card-title>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="blue darken-1" text @click="closeDelete">Cancel</v-btn>
                  <v-btn color="blue darken-1" text @click="deleteItemConfirm">OK</v-btn>
                  <v-spacer></v-spacer>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-toolbar>
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <!-- <v-icon small class="mr-2" @click="editItem(item)">mdi-eye</v-icon> -->
          <v-icon small class="mr-2" @click="editItem(item)">mdi-pencil</v-icon>
          <!-- <v-icon small @click="deleteItem(item)">mdi-delete</v-icon> -->
        </template>
        <template v-slot:no-data>
          <v-btn color="primary" @click="getItems">No data</v-btn>
        </template>
      </v-data-table>
    </div>
  </div>
</template>

<script>
import { fetchCollaborations, addCollaboration, editCollaboration, deleteCollaboration } from "@/services"
import TheSidebar from "@/components/TheSidebar"

export default {
  name: "Collaborations",
  components: {
    TheSidebar,
  },
  data() {
    return {
      valid: false,
      perPage: 10,
      sortDirection: false,
      page: 1,
      total: 0,
      sortBy: "title",
      spinnerActive: false,
      dialog: false,
      dialogDelete: false,
      headers: [
        { text: "Collaboration", value: "title" },
        { text: "Actions", value: "actions", sortable: false },
      ],
      items: [],
      fileName: null,
      imageUpdated: false,
      collaborationImage: null,
      editedIndex: -1,
      editedItem: { website_config: [{ value: "" }, { value: "" }] },
      defaultItem: { website_config: [{ value: "" }, { value: "" }] },
      rules: {
        required: (v) => !!v || "Required.",
        requireLength: (v) => (v != undefined && v.length > 0) || "Required",
        sizes: (files) =>
          !files ||
          !files.some((file) => file.size > 20e6) ||
          "Image size should be less than 1 MB!",
        size: (file) =>
          !file || file.size < 20e6 || "Image size should be less than 20 MB!",
        slug: (v) =>
          /^[a-z0-9-]+$/gm.test(v) ||
          "Must be lowercase and with '-' instead of 'whitespace'.",
      },
    }
  },
  computed: {
    formTitle() {
      return this.editedIndex === -1 ? "New Item" : "Edit Item"
    },
  },
  watch: {
    dialog(val) {
      val || this.close()
    },
    dialogDelete(val) {
      val || this.closeDelete()
    },
  },
  mounted() {
    this.getItems()
  },
  methods: {
    async getItems() {
      if (this.spinnerActive) return
      this.items = []
      setTimeout(async () => {
        let tableData = {}
        tableData["perPage"] = this.perPage
        tableData["sortBy"] = this.sortBy
        tableData["sortDirection"] = this.sortDirection ? "DESC" : "ASC"
        tableData["page"] = this.page
        this.spinnerActive = true
        await fetchCollaborations(tableData).then((res) => {
          if (res.data) {
            this.items = res.data.data.data
            this.spinnerActive = false
            this.total = res.data.data.total
          }
        }).catch((error) => {
          console.log(error)
          this.spinnerActive = false
        })
      }, 0)
    },
    loadImage() {
      if (this.editedItem.image) {
        var file = this.editedItem.image
        var reader = new FileReader()
        this.fileName = file.name
        reader.onloadend = async () => {
          if (reader.result.length * (3 / 4) < 20e6) {
            this.collaborationImage = reader.result
            this.imageUpdated = true
            this.editedItem.image = null
          }
        }
        reader.readAsDataURL(file)
      }
    },
    getImage(imageName) {
      if (imageName && imageName.includes("data:image")) {
        return imageName  
      } else {
        return window.location.origin === 'http://localhost:8080' ? 'http://stage.cor2ed.xyz/api/image/' + imageName : window.location.origin + '/api/image/' + imageName
      }
    },
    editItem(item) {
      this.editedIndex = this.items.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.collaborationImage = item.image
      this.editedItem.image = null
      this.dialog = true
    },
    deleteItem(item) {
      this.editedIndex = this.items.indexOf(item)
      item.image = null
      this.editedItem = Object.assign({}, item)
      this.editedItem.image = null
      this.dialogDelete = true
    },
    async deleteItemConfirm() {
      await deleteCollaboration(this.editedItem).then(() => {
        this.getItems()
        this.closeDelete()
      }).then((error) => {
        console.log("error deleteCollaboration => ", error)
      })
    },
    close() {
      this.dialog = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
        this.collaborationImage = null
      })
    },
    closeDelete() {
      this.dialogDelete = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
    },
    async save() {
      let formValidated = this.$refs.form.validate()
      if (!formValidated) return
      let data = Object.assign({}, this.editedItem)
      if (this.imageUpdated) {
        data["image"] = this.collaborationImage
        data["file_name"] = this.fileName
      }
      if (this.editedIndex > -1) {
        // EDIT
        await editCollaboration(data).then(() => {
          this.getItems()
          this.close()
        }).then((error) => {
          console.log("error editCollaboration => ", error)
        })
      } else {
        // ADD
        await addCollaboration(data).then(() => {
          this.getItems()
          this.close()
        }).then((error) => {
          console.log("error addCollaboration => ", error)
        })
      }
    },
  },
}
</script>

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"page-wrapper"},[_c('TheSidebar'),_c('div',{staticClass:"right-content-wrapper"},[_c('v-data-table',{ref:"table",staticClass:"elevation-1",staticStyle:{"width":"100%"},attrs:{"headers":_vm.headers,"items":_vm.items,"items-per-page":_vm.perPage,"footer-props":{ 'items-per-page-options': [10, 20, 50, 100, 500] },"sort-by":_vm.sortBy,"sort-desc":_vm.sortDirection,"page":_vm.page,"server-items-length":_vm.total,"loading":_vm.spinnerActive},on:{"update:itemsPerPage":function($event){_vm.perPage=$event},"update:items-per-page":function($event){_vm.perPage=$event},"update:sortBy":function($event){_vm.sortBy=$event},"update:sort-by":function($event){_vm.sortBy=$event},"update:sortDesc":function($event){_vm.sortDirection=$event},"update:sort-desc":function($event){_vm.sortDirection=$event},"update:page":function($event){_vm.page=$event},"update:serverItemsLength":function($event){_vm.total=$event},"update:server-items-length":function($event){_vm.total=$event},"update:options":_vm.getItems},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-toolbar-title',[_vm._v("Collaborations")]),_c('v-divider',{staticClass:"mx-4",attrs:{"inset":"","vertical":""}}),_c('v-spacer'),_c('v-dialog',{attrs:{"max-width":"1000px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mb-2",attrs:{"color":"primary","dark":""}},'v-btn',attrs,false),on),[_vm._v("New Item")])]}}]),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-form',{ref:"form",attrs:{"action":"/"},on:{"submit":function($event){$event.preventDefault();return _vm.save()}},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('v-card-title',[_c('span',{staticClass:"text-h5"},[_vm._v(_vm._s(_vm.formTitle))])]),_c('v-card-text',[_c('v-container',[_c('v-row',[_c('v-text-field',{attrs:{"rules":[_vm.rules.required],"label":"Title"},model:{value:(_vm.editedItem.title),callback:function ($$v) {_vm.$set(_vm.editedItem, "title", $$v)},expression:"editedItem.title"}})],1),_c('v-row',[_c('v-textarea',{attrs:{"rules":[_vm.rules.required],"label":"Description"},model:{value:(_vm.editedItem.description),callback:function ($$v) {_vm.$set(_vm.editedItem, "description", $$v)},expression:"editedItem.description"}})],1),_c('v-row',[_c('v-text-field',{attrs:{"rules":[_vm.rules.required],"label":"URL (link)"},model:{value:(_vm.editedItem.url),callback:function ($$v) {_vm.$set(_vm.editedItem, "url", $$v)},expression:"editedItem.url"}})],1),_c('v-row',[_c('v-file-input',{attrs:{"accept":"image/* //","rules":[!!_vm.collaborationImage || 'Required.', _vm.rules.size],"placeholder":"Pick an image","input":_vm.loadImage(),"label":"Image","prepend-icon":"mdi-paperclip","outlined":"","show-size":1000},model:{value:(_vm.editedItem.image),callback:function ($$v) {_vm.$set(_vm.editedItem, "image", $$v)},expression:"editedItem.image"}}),_c('div',{staticStyle:{"width":"100%"}},[(_vm.collaborationImage)?_c('img',{staticClass:"mb-4",attrs:{"src":_vm.getImage(_vm.collaborationImage),"height":"100"}}):_vm._e()])],1),_c('v-row',[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"rules":[_vm.rules.required],"label":"Image alt"},model:{value:(_vm.editedItem.alt_image),callback:function ($$v) {_vm.$set(_vm.editedItem, "alt_image", $$v)},expression:"editedItem.alt_image"}},'v-text-field',attrs,false),on))]}}])},[_c('span',[_vm._v("The Alt tags are used to describe the image to "),_c('br'),_vm._v("search engines (cannot be seen by the user). "),_c('br'),_vm._v("Try to create a simple description of the image "),_c('br'),_vm._v("as if you were describing it to someone with their eyes closed. ")])])],1)],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":_vm.close}},[_vm._v("Cancel")]),_c('v-btn',{attrs:{"color":"blue darken-1","text":"","type":"submit"}},[_vm._v("Save")])],1)],1)],1)],1),_c('v-dialog',{attrs:{"max-width":"1000px"},model:{value:(_vm.dialogDelete),callback:function ($$v) {_vm.dialogDelete=$$v},expression:"dialogDelete"}},[_c('v-card',[_c('v-card-title',{staticClass:"text-h5"},[_vm._v("Are you sure you want to delete this item?")]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":_vm.closeDelete}},[_vm._v("Cancel")]),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":_vm.deleteItemConfirm}},[_vm._v("OK")]),_c('v-spacer')],1)],1)],1)],1)]},proxy:true},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.editItem(item)}}},[_vm._v("mdi-pencil")])]}},{key:"no-data",fn:function(){return [_c('v-btn',{attrs:{"color":"primary"},on:{"click":_vm.getItems}},[_vm._v("No data")])]},proxy:true}],null,true)})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }